import React from 'react'
import { styled } from 'styled-components';

const Container = styled.button`
  border-radius: 38px;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 600;
  padding: 22px 55px;
  background-color: #fff;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
`

const Button = ({children, link, ...rest}) => {
  return (
    <Container {...rest}>
      {children}
    </Container>
  )
}

export default Button
