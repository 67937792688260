import React from 'react'
import { styled } from 'styled-components';

const Container = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 54px;
  padding-right: 54px;
  @media(max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`

const Maxwidth = ({ children, nominheight, path }) => {
  return (
    <Container>{children}</Container>
  )
}

export default Maxwidth;
