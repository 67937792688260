import React, {useContext} from 'react'
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
import styled from 'styled-components'
import Icon from '../../images/basket.svg'

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  column-gap: 15px;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  pointer-events: ${props => props.open ? 'none':'all'};
  opacity: ${props => props.open ? 0:1};
  @media(max-width: 768px) {
    display: ${props => props.open ? 'none' : 'flex'};
  }
  p {
    font-size: 16px;
    display: inline-block;
    @media(max-width: 768px) {
      display: none;
    }
  }
`

const Basket = ({open}) => {

  const { state } = useContext(SnipcartContext);
  const { userStatus, cartQuantity } = state;

  const items = cartQuantity || 0;

  return (
    <>
      { items > 0 &&
        <Container open={open} className="snipcart-checkout"><img src={Icon} /> <p>BASKET</p> ({items})</Container>
      }
    </>
  )
}

export default Basket
