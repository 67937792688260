import React, { useState } from 'react'
import { styled } from 'styled-components';

import Maxwidth from '../maxwidth'
import Input from '../input'
import Button from '../button'

import Facebook from '../../images/facebook_white.svg'
import Instagram from '../../images/instagram_white.svg'
import YouTube from '../../images/youtube_white.svg'

const Container = styled.footer`
  color: #fff;
  display: flex;
  column-gap: 100px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 140px;
  padding-bottom: 140px;
  @media(max-width: 768px) {
    flex-direction: column;
    row-gap: 50px;
    padding-bottom: 50px;
    max-width: calc(100vw - 4rem);
  }
`

const Left = styled.div`
  form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    input {
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 47px;
      @media(max-width: 768px) {
        font-size: 26px;
        line-height: 1.4;
      }
    }
    button {
      margin-top: 20px;
    }
  }
`

const Right = styled.div`
  width: 370px;
  @media(max-width: 768px) {
    width: 100%;
    max-width: calc(100vw - 2rem);
  }
  p {
    font-size: 18px;
    line-height: 28px;
    a {
      color: #fff;
    }
  }
`

const Socials = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    cursor: pointer;
  }
  li {
    font-size: 34px;
    line-height: 44px;
    font-family: Montserrat, sans-serif;
    display: flex;
    column-gap: 16px;
    align-items: center;
    margin-bottom: 4px;
    font-weight: 300;
    @media(max-width: 768px) {
      font-size: 22px;
      line-height: 32px;
      padding-bottom: 0.5rem;
    }
    a {
      text-decoration: none;
      cursor: pointer;
      color: var(--white);
    }
  }
`

const TermsConditions = styled.ul`
  list-style: none;
  font-size: 22px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  font-weight: 300;
  margin-top: 66px;
  margin-bottom: 66px;
  a {
    text-decoration: none;
    color: var(--white);
    border-bottom: 1px solid var(--white);
  }
`

const Footer = () => {

  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [action, setAction] = useState('Submit');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setAction('Submitting...')

    const name_parts = firstname.split(' ');
    const fname = name_parts[0].trim();
    const lname = name_parts.slice(1).join(' ').trim();

    const apiUrl = 'https://api.brevo.com/v3/contacts';

    const form_data = {
        "updateEnabled": true,
        "email": email,
        "emailBlacklisted": false,
        "smsBlacklisted": false,
        "attributes": {
          "FIRSTNAME": fname,
          "LASTNAME": lname
        },
        "listIds": [3]   //Replace 4 with the ID of your Brevo contact list, or add comma separated values i.e. 4,6,8
    }

    try {

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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',
        },
        body: JSON.stringify(form_data)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle the successful response, if needed
      const data = await response.json();
      console.log('Success:', data);
      setAction('Sent!')
    } catch (error) {
      // Handle errors
      console.error('Error:', error.message);
    }
  };

  return (
    <Maxwidth>
      <Container>
        <Left>
          <h3>Register for the latest news and updates</h3>
          <form autoComplete="off">
            <Input name="fullname" placeholder="Your Full Name *" value={firstname} setValue={setFirstname} />
            <Input type="email" name="email" placeholder="Your Email Address *" value={email} setValue={setEmail} />
            <div><Button disabled={email.length > 1 && email.includes('@') && email.includes('.') && firstname.length > 1 ? false : true}onClick={(e) => handleSubmit(e)}>{action}</Button></div>
          </form>
        </Left>
        <Right>
          <Socials>
            <li><a href="https://www.facebook.com/groups/pancelticrace"><img src={Facebook} alt="Facebook"/></a> <a href="https://www.facebook.com/groups/pancelticrace">Facebook</a></li>
            <li><a href="https://www.instagram.com/pancelticraceseries/"><img src={Instagram} alt="Instagram"/></a>  <a href="https://www.instagram.com/pancelticraceseries/">Instagram</a></li>
            <li><a href="https://www.youtube.com/@PanCelticRaceSeries"><img src={YouTube} alt="YouTube"/></a>  <a href="https://www.youtube.com/@PanCelticRaceSeries">YouTube</a></li>
          </Socials>
          <TermsConditions>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-of-use">Terms of Use</a></li>
          </TermsConditions>
          <p>Copyright &copy; Pan Celtic Race Ltd.</p>
        </Right>
      </Container>
    </Maxwidth>
  )
}

export default Footer
