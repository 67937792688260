import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { styled } from "styled-components";
import Button from "../button";
import DownCaret from "../../images/down_caret.svg";

const Container = styled.div`
    position: relative;
    color: #fff;
    height: auto;
    max-height: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    background-color: #000;
    transition: all 0.25s ease-in-out;
    transform: translateY(-4px);
    &.open {
        transform: translateY(0px);
        overflow-y: auto;
        overflow-x: hidden;
        pointer-events: all;
        background-color: #000;
        opacity: 1;
        z-index: 9999999999;
        max-height: calc(100vh - 210px);
    }
`;

const Heading = styled.div`
    width: 100vw;
    border-top: 1px solid #3c3c3c;
    cursor: ${(props) => (props.nopointer ? "default" : "pointer")};
    &.hidden {
        display: none;
    }
    &.open {
        border-bottom: 1px solid transparent;
    }
    h2 {
        &.hidden {
            display: none;
        }
        font-family: "Oswald", sans-serif;
        text-transform: uppercase;
        font-size: 46px;
        color: #fff;
        line-height: 90px;
        max-width: 1100px;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
        padding-bottom: 8px;
        justify-content: space-between;
        @media (max-width: 768px) {
            font-size: 28px;
            line-height: 64px;
        }
        img {
            margin-top: 8px;
            transition: all 0.25s ease-in-out;
            &.up {
                transform: rotate(180deg);
            }
        }
    }
`;

const Menu = styled.nav`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @media (max-width: 768px) {
        overflow-y: auto;
        max-height: calc(100vh - 100px);
    }
    ul {
        max-width: 995px;
        width: 100%;
        list-style: none;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        max-height: 0;
        opacity: 0;
        overflow-y: hidden;
        transition: all 0.25s ease-in-out;
        &.open {
            margin-top: 33px;
            margin-bottom: 60px;
            opacity: 1;
            height: auto;
            max-height: 100vh;
            overflow-y: visible;
            @media (max-width: 768px) {
                overflow-y: visible;
                margin-top: 0;
                margin-bottom: 20px;
            }
        }
        li {
            font-family: Montserrat, sans-serif;
            font-size: 22px;
            line-height: 60px;
            text-align: left;
            text-transform: uppercase;
            margin-left: 40px;
            font-weight: 300;
            position: relative;
            a {
                color: #fff;
                text-decoration: none;
                cursor: pointer !important;
                transition: opacity 0.25s ease-in-out;
                &.disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
`;

const ButtonContainer = styled.div`
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    height: 90px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 1rem;
`;

const Popover = ({ setOpen, open }) => {
    const [section, setSection] = useState(false);

    useEffect(() => {
        if (open === false) {
            setSection(false);
        }
    }, [open]);

    const handleSection = (e, clicked_section) => {
        e.preventDefault();
        if (section === clicked_section) {
            setSection(false);
        } else {
            setSection(clicked_section);
        }
    };

    const handleClick = (e, url) => {
        e.preventDefault();
        navigate(url);
        setTimeout(function () {
            setOpen(false);
        }, 25);
    };

    return (
        <Container className={open ? "open" : ""}>
            <Menu>
                <Heading
                    className={section === "the-ultra" ? "open" : "closed"}
                    onClick={(e) => handleSection(e, "the-ultra")}
                >
                    <h2 className="disabled">
                        The Ultra
                        <img
                            alt=""
                            className={section === "the-ultra" ? "up" : "down"}
                            src={DownCaret}
                        />
                    </h2>
                </Heading>
                <ul className={section === "the-ultra" ? "open" : "closed"}>
                    <li>
                        <a
                            href="#"
                            onClick={(e) =>
                                handleClick(e, "/ultra/2024/results")
                            }
                        >
                            Results 2024
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) =>
                                handleClick(e, "/ultra/past-editions")
                            }
                        >
                            Past Editions
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) => handleClick(e, "/ultra/rules")}
                        >
                            Rules
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) => handleClick(e, "/ultra/faq")}
                        >
                            FAQ
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) =>
                                handleClick(e, "/ultra/terms-conditions")
                            }
                        >
                            Terms & Conditions
                        </a>
                    </li>
                </ul>
                <Heading
                    className={
                        section === "the-gravel-rally" ? "open" : "closed"
                    }
                    onClick={(e) => handleSection(e, "the-gravel-rally")}
                >
                    <h2>
                        The Gravel Rally{" "}
                        <img
                            alt=""
                            className={
                                section === "the-gravel-rally" ? "up" : "down"
                            }
                            src={DownCaret}
                        />
                    </h2>
                </Heading>
                <ul
                    className={
                        section === "the-gravel-rally" ? "open" : "closed"
                    }
                >
                    <li>
                        <a
                            href="#"
                            onClick={(e) => handleClick(e, "/gravel/results")}
                        >
                            Results 2024
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={(e) => handleClick(e, "/gravel")}>
                            The Gravel Rally 2025
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) =>
                                handleClick(e, "/gravel/sectors-courses/")
                            }
                        >
                            Sectors & Courses
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) => handleClick(e, "/gravel/faq")}
                        >
                            FAQ
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) =>
                                handleClick(e, "/gravel/past-editions")
                            }
                        >
                            Past Editions
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) =>
                                handleClick(e, "/gravel/terms-conditions")
                            }
                        >
                            Terms & Conditions
                        </a>
                    </li>
                </ul>
                <Heading onClick={(e) => handleClick(e, "/journal")}>
                    <h2>Journal</h2>
                </Heading>
                <Heading
                    className={section === "about" ? "open" : "closed"}
                    onClick={(e) => handleSection(e, "about")}
                >
                    <h2>
                        About{" "}
                        <img
                            alt=""
                            className={section === "about" ? "up" : "down"}
                            src={DownCaret}
                        />
                    </h2>
                </Heading>
                <ul className={section === "about" ? "open" : "closed"}>
                    <li>
                        <a href="#" onClick={(e) => handleClick(e, "/team")}>
                            The Team
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) => handleClick(e, "/partners")}
                        >
                            Partners
                        </a>
                    </li>
                </ul>
                <Heading onClick={(e) => handleClick(e, "/galleries")}>
                    <h2>Galleries</h2>
                </Heading>
                <Heading onClick={(e) => handleClick(e, "/contact")}>
                    <h2>Contact</h2>
                </Heading>
                <Heading onClick={(e) => handleClick(e, "/shop")}>
                    <h2>Shop</h2>
                </Heading>
                <Heading nopointer>
                    <ButtonContainer>
                        <Button
                            onClick={(e) =>
                                handleClick(
                                    e,
                                    "https://riders.pancelticrace.com",
                                )
                            }
                        >
                            Rider Login
                        </Button>
                    </ButtonContainer>
                </Heading>
            </Menu>
        </Container>
    );
};

export default Popover;
