import React from 'react'
import { styled } from 'styled-components';

const Container = styled.input`
  border-color: transparent;
  border-bottom: 1px solid var(--border);
  font-family: Montserrat, sans-serif;
  color: var(--white);
  background-color: transparent;
  transition: border 0.25s ease-in-out;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--white);
    opacity: 1; /* Firefox */
  }

  &:autofill {
    background-color: #000;
    color: var(--white);
  }

  &:-webkit-autofill {
    background-color: #000;
    color: var(--white);
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--white);
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--white);
  }


  &:focus {
    outline: 0;
    border-bottom: 1px solid #555;
  }
`

const Input = (props, ...rest) => {

  const { placeholder, name, value, setValue, type="text" } = props;

  return (
    <Container autoComplete="off" type={type} name={name} value={value} placeholder={placeholder} onChange={(e) => setValue(e.target.value)} {...rest} />
  )
}

export default Input;
