import React, { useState } from "react";
import { motion } from "framer-motion";
import { ReactLenis } from '@studio-freight/react-lenis'
import Helmet from 'react-helmet'
import Nav from '../components/nav'
import Footer from '../components/footer'
import GlobalStyle from "../components/globalstyle";

export default function Layout(props) {

  const { children, path } = props;

  const [open, setOpen] = useState(false);

  const hide_footer_paths = ['/login/']

  // ReactLenis

  return (
    <React.Fragment>
      <GlobalStyle />
      { open &&
      <Helmet bodyAttributes={{ class: 'open' }} />
      }
      <Nav setOpen={setOpen} open={open} />

        <motion.div
          key={path}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}>
          <main>
            {children}
          </main>
        </motion.div>


      {!hide_footer_paths.includes(path) &&
        <Footer />
      }

    </React.Fragment>
  );

}
